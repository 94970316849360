<template>
  <div class="academic-years">
    <lenon-list-page
      title="Marked Assessments"
      :columns="columns"
      :rows="markedAssessments"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      enable-multi-select
      show-profile-photo
      search-placeholder="Search"
      @onRowSelect="onSelect"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px !important;"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Select Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="ay_term_id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedClassId"
            placeholder="Select Class"
            :options="classes"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <!--          <lenon-select-->
          <!--            v-model="selectedSubjectId"-->
          <!--            placeholder="Select Subject"-->
          <!--            :options="subjects"-->
          <!--            label-name="name"-->
          <!--            value-name="id"-->
          <!--            class="mr-1"-->
          <!--            style="width: 150px !important;"-->
          <!--          />-->
          <lenon-select
            v-model="selectedMarkerId"
            placeholder="Select Marker Setting"
            :options="markerSettings"
            label-name="title"
            value-name="code"
            class="mr-1"
            style="width: 150px !important;"
          />
          <lenon-button
            icon="DownloadIcon"
            label="Download"
            tool-tip-text="Download marked answer sheets"
            class="mr-1"
            variant="outline-primary"
            icon-only
            @onClick="downloadSheet()"
          />
          <lenon-button
            icon="UploadIcon"
            label="Upload"
            tool-tip-text="Upload answer sheets for marking"
            class=""
            icon-only
            @onClick="showMarkerSettingModal()"
          />
        </div>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
      <template #score="{row}">
        <b-badge variant="light-success">
          {{ row.item.score }} / {{ selectedMarker.total_questions }}
        </b-badge>
      </template>
      <template #status="{row}">
        <b-badge :variant="getStatusVariant(row.item.status)">
          {{ row.item.status.toString().toUpperCase() }}
        </b-badge>
      </template>
      <template #actions="{row}">
        <lenon-button
          variant="flat-primary"
          icon-only
          icon="Edit2Icon"
          :tool-tip-text="row.item.status==='recorded'?'Score already recorded':'Update marking'"
          :disabled="row.item.status==='recorded'"
          @onClick="()=>{showUpdateMarkingModal(row.item)}"
        />
      </template>
    </lenon-list-page>
    <lenon-modal
      :show="markerSettingModalOpened"
      title="Upload Answer Sheets"
      ok-text="Proceed"
      :loading="uploadingBooklets"
      :ok-disabled="!uploadedFiles.length"
      @onClose="markerSettingModalOpened = false"
      @onOk="uploadAnswerBooklets"
    >
      <lenon-file-button
        multiple
        :label="isPdf?'Upload Scanned Pdf Files':'Upload Captured Images'"
        variant="outline-primary"
        :accept="isPdf?'.pdf':'.jpg,.jpeg,.png'"
        @onFileUpload="processUploadedFiles"
      />
      <div
        v-if="uploadedFiles.length"
        class="mt-1 row"
      >
        <div
          v-for="(file,i) in uploadedFiles"
          :key="i"
          class="col-12"
        >
          <div class="d-flex flex-wrap align-items-center">
            <b-badge variant="light-success">
              {{ file.name }}
            </b-badge>
            <lenon-button
              icon-only
              icon="XIcon"
              variant="flat-danger"
              @onClick="removeFile(i)"
            />
          </div>
        </div>
      </div>
      <div class="mt-1" />
      <b-form-checkbox v-model="isPdf">
        Upload Scanned Pdf
      </b-form-checkbox>
      <div class="mt-1 text-danger">
        <small>Note: To accurately mark questions, make sure uploaded files are pictures or scans(pdf) of answer sheets generated on Lenon. Unsupported documents will be ignored.</small>
      </div>
    </lenon-modal>
    <lenon-modal
      :show="markingUpdateModalOpened"
      :title="`Update Marking - ${currentMarking.name}`"
      ok-text="Update"
      :loading="updatingMarking"
      size="md"
      @onClose="markingUpdateModalOpened = false"
      @onOk="updateMarking"
    >
      <div class="row mb-1">
        <div class="col-12">
          <b-badge variant="light-primary">
            Total Score: <strong>{{ totalScore }}</strong>
          </b-badge>
        </div>
      </div>
      <div
        v-for="(item,x) in currentMarking.choices"
        :key="x"
        class="row align-items-center"
      >
        <div
          class="col-md-6"
        >
          <lenon-input
            v-model="item.answer"
            name="option"
            :placeholder="`No.${x+1} Selected Option`"
            rules="required|valid_answer"
            :label="`No.${x+1} Selected Option`"
            @input="(val)=>{updateStatus(x,val)}"
          />
        </div>
        <div class="col-md-3 mt-1">
          <b-badge variant="light-secondary">
            ANSWER: {{ selectedMarker.answers[x].answer }}
          </b-badge>
        </div>
        <div
          class="col-md-3 mt-1"
        >
          <b-badge :variant="item.answer===selectedMarker.answers[x].answer?'success':'danger'">
            <feather-icon :icon="item.answer===selectedMarker.answers[x].answer?'CheckIcon':'XIcon'" /> {{ item.answer===selectedMarker.answers[x].answer?'Correct':'Wrong' }}
          </b-badge>
        </div>
      </div>
    </lenon-modal>
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import MarkerSetup from '@/views/smartmarker/marker-settings/MarkerSetup.vue'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import {
  BBadge, BCard, BCol, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import logData from '@/libs/log'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import LenonSelect from '@/lenon/components/LenonSelect'
import confirm from '@/lenon/mixins/confirm'
import LenonModal from '@/lenon/components/LenonModal'
import LenonFileButton from '@/lenon/components/LenonFileButton'
import LenonInput from '@/lenon/components/LenonInput'

export default {
  name: 'MarkerSettings',
  components: {
    LenonInput,
    LenonFileButton,
    LenonModal,
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    MarkerSetup,
    LenonButton,
    BCard,
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      markerSettingModalOpened: false,
      tableLoading: false,
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
      selectedSubjectId: null,
      selectedMarkerId: null,
      isPdf: false,
      uploadedFiles: [],
      markingUpdateModalOpened: false,
      updatingMarking: false,
      columns: [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'name',
          label: 'Student Name',
        },
        {
          key: 'student_id',
          label: 'Student ID',
        },
        {
          key: 'score',
          label: 'Score',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      uploadingBooklets: false,
      currentMarking: {},
      selectedStudents: [],
    }
  },
  computed: {
    markedAssessments() {
      return this.$store.getters['markers/markedAssessments']
    },
    correctAnswers() {
      return this.currentMarking.choices ? this.currentMarking.choices.filter(c => c.status) : []
    },
    totalScore() {
      return this.correctAnswers.length
    },
    monitorSettings() {
      return +this.selectedTermId
          * +this.selectedClassId
          * +this.selectedAcademicYearId
    },
    previousSettings() {
      return this.$store.getters['markers/markedSettings']
    },
    markerSettings() {
      return this.$store.getters['markers/markers']
    },
    selectedMarker() {
      return this.markerSettings.find(m => m.code === this.selectedMarkerId)
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
  },
  watch: {
    isPdf(val) {
      this.uploadedFiles = []
    },
    monitorSettings(val) {
      if (val) {
        this.fetchMarkerSettings()
      }
    },
    selectedMarkerId(val) {
      if (val) {
        this.fetchMarkedAssessments()
        this.$store.commit('markers/setMarkedSettings', {
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedTermId: this.selectedTermId,
          selectedClassId: this.selectedClassId,
          selectedMarkerId: this.selectedMarkerId,
        })
      } else {
        this.$store.commit('markers/setMarkedAssessments', [])
      }
    },
  },
  mounted() {
    this.setSettings()
  },
  methods: {
    onSelect(students) {
      this.selectedStudents = students
    },
    setSettings() {
      this.selectedAcademicYearId = this.previousSettings.selectedAcademicYearId
      this.selectedTermId = this.previousSettings.selectedTermId
      this.selectedClassId = this.previousSettings.selectedClassId
      this.selectedMarkerId = this.previousSettings.selectedMarkerId
    },
    showUpdateMarkingModal(item) {
      const updatedMarking = { ...item }
      const { answers } = this.selectedMarker
      updatedMarking.choices = JSON.parse(updatedMarking.choices).map((c, i) => ({ answer: c, status: answers[i].answer === c }))
      this.currentMarking = updatedMarking
      this.markingUpdateModalOpened = true
    },
    updateStatus(index, val) {
      this.currentMarking.choices[index].status = this.selectedMarker.answers[index].answer === val
    },
    updateMarking() {
      const correct = this.correctAnswers
      const score = this.totalScore
      const choices = this.currentMarking.choices.map(a => a.answer)
      this.updatingMarking = true
      this.$http.put(`markers/${this.currentMarking.student_id}/${this.currentMarking.marker_id}/update`, { score, choices }).then(res => {
        this.showSuccess('Updated successfully')
        this.markedAssessments = this.markedAssessments.map(m => {
          if (m.student_id === this.currentMarking.student_id) {
            return { ...m, score, choices: JSON.stringify(choices) }
          }
          return m
        })
      }).catch(err => {
        this.showError('Failed to update marking')
      }).finally(() => {
        this.updatingMarking = false
      })
    },
    removeFile(index) {
      this.uploadedFiles.splice(index, 1)
    },
    processUploadedFiles(files) {
      this.uploadedFiles = [...this.uploadedFiles, ...files]
    },
    uploadAnswerBooklets() {
      const fd = new FormData()
      fd.append('is_pdf', this.isPdf)
      this.uploadedFiles.forEach(f => {
        fd.append('booklets[]', f)
      })
      this.uploadingBooklets = true
      this.$http.post('markers/upload-answer-booklets', fd).then(res => {
        this.uploadedFiles = []
        this.isPdf = false
        this.showSuccess('Booklets Uploaded Successfully, You will be notified when marking completes')
      }).catch(err => {
        this.showError('Failed to upload booklets')
      }).finally(() => {
        this.uploadingBooklets = false
      })
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    downloadSheet() {
      if (this.selectedStudents.length) {
        this.tableLoading = true
        const student_ids = this.selectedStudents.map(s => s.student_id)
        this.$http.post(`markers/download-marked-answer-sheet/${this.selectedMarker.code}`, { student_ids })
          .then(res => {
            this.downloadFile(res.data, 'Marked Answer Sheet')
          })
          .catch(err => {
            logData(err)
            this.showError('Failed to download sheet')
          }).finally(() => {
            this.tableLoading = false
          })
      } else {
        this.showInfo('Please select a student to download sheet')
      }
    },
    getStatusVariant(status) {
      let variant = 'light-warning'
      // eslint-disable-next-line default-case
      switch (+status) {
        case 'marked':
          variant = 'light-success'
          break
        case 'pending':
          variant = 'light-danger'
          break
      }
      return variant
    },
    showMarkerSettingModal() {
      this.markerSettingModalOpened = true
    },
    fetchMarkerSettings() {
      this.tableLoading = true
      this.$http.get(`markers?ay_id=${this.selectedAcademicYearId}&term_id=${this.selectedTerm.id}&class_id=${this.selectedClassId}&limited=yes`).then(res => {
        this.tableLoading = false
        this.$store.commit('markers/setMarkers', res.data)
      })
        .catch(err => {
          this.tableLoading = false
          logData(err)
          this.showError('Failed to load markers')
        })
    },
    fetchMarkedAssessments() {
      this.tableLoading = true
      this.$http.get(`markers/${this.selectedMarkerId}/marked`).then(res => {
        this.tableLoading = false
        this.$store.commit('markers/setMarkedAssessments', res.data)
      })
        .catch(err => {
          this.tableLoading = false
          logData(err)
          this.showError('Failed to load markers')
        })
    },
  },
}
</script>
